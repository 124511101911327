import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';

gsap.registerPlugin(ScrollTrigger);

const profiles = [
  {
    id: 1,
    image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2025/01/Profile-1.jpg`,
    name: "Member Name 1",
    position: "Position 1",
  },
  {
    id: 2,
    image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2025/01/Profile-2.jpg`,
    name: "Member Name 2",
    position: "Position 2",
  },
  {
    id: 3,
    image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2025/01/Profile-3.jpg`,
    name: "Member Name 3",
    position: "Position 3",
  },
  {
    id: 4,
    image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2025/01/Profile-4.jpg`,
    name: "Member Name 4",
    position: "Position 4",
  },
  {
    id: 5,
    image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2025/01/Profile-5.jpg`,
    name: "Member Name 5",
    position: "Position 5",
  },
  {
    id: 6,
    image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2025/01/Profile-6.jpg`,
    name: "Member Name 6",
    position: "Position 6",
  },
  {
    id: 7,
    image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2025/01/Profile-5.jpg`,
    name: "Member Name 7",
    position: "Position 7",
  },
  {
    id: 8,
    image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2025/01/Profile-3.jpg`,
    name: "Member Name 3",
    position: "Position 3",
  },
  {
    id: 9,
    image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2025/01/Profile-3.jpg`,
    name: "Member Name 3",
    position: "Position 3",
  },
  {
    id: 10,
    image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2025/01/Profile-3.jpg`,
    name: "Member Name 3",
    position: "Position 3",
  },
  {
    id: 11,
    image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2025/01/Profile-3.jpg`,
    name: "Member Name 3",
    position: "Position 3",
  },
  {
    id: 12,
    image: `${process.env.REACT_APP_API_URL}/wp-content/uploads/2025/01/Profile-3.jpg`,
    name: "Member Name 3",
    position: "Position 3",
  },
];



const AboutScreen = () => {

  useEffect(() => {
    const imageBox = document.querySelector(".image-col");
    const textBox = document.querySelector(".text-col");
  
    gsap.fromTo(
      imageBox,
      {
        scale: 1,
        x: "0%", // Initial position of the image
      },
      {
        scale: 0.5, // Zoom out
        x: "0%", // Move the image slightly towards the left as it zooms out
        y: "0px", // Ensure the image stays on the same vertical plane
        scrollTrigger: {
          trigger: ".scroll-section",
          start: "top 150%", // Start the animation when the top of the image reaches the middle of the viewport
          end: "bottom top", // End when the bottom of the container hits the top of the viewport
          scrub: true, // Smooth animation
        },
        transformOrigin: "left center", // Zoom out from the left side, making the right side lean out
      }
    );
  
    gsap.fromTo(
      textBox,
      {
        opacity: 0,
        y: -20,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: ".scroll-section",
          start: "top 150%", // Start when the top of the container hits the middle of the viewport
          end: "bottom top", // End when the bottom of the container hits the top of the viewport
          scrub: true, // Smooth animation
        },
      }
    );
  }, []);
  
  

  
  
    

  return (
 
    <HelmetProvider>

    <div className="AboutScreen">
         <Helmet>
             <title>About</title>
             <meta name="description" content="About" />
         </Helmet>

      <div className="aboutintro-bannner">
        <div className="container-fluid">
          <div className="row">
            <div className="col intro-about p-0">
              <div className="about-banner-img">
                <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/07/Banner-1.png`}
                  alt="about-intro-img"
                  className="about-intro-banner"
                  width="100%"
                  height="auto"
                />
              </div>
              <div className="slider_1">
                <h2 className="slider__word_1 white">For 16 Years</h2>
                <h2 className="slider__word_1 white">We have built</h2>
                <h2 className="slider__word_1 white">numerous  brand stories</h2>
              </div>

              <div className="about-intro-bottom-text">
                <h2 className="roboto-light white text-center about-h2">
                  Create <span className="red roboto-bold">purpose-based experiences</span>
                  <br />
                  between <span className="red roboto-bold">brands</span> and{" "}
                  <span className="red roboto-bold">consumers</span> that make a
                  difference in<br />
                  people’s lives, communities, and our planet
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="integrity">
        <div className="container-fluid">
          <div className="row row-cols-5">
            <div className="col bg-col-1 hover-box p-0">
            <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/07/1.png`} alt='about-img-in' className='about-img-in' width='100%' loading="lazy" />
              <div className="text-box">
                <h3 className="roboto-thin white">Integrity</h3>
              </div>
              <div className="about-text-description">
                <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                </p>
              </div>
            </div>
            <div className="col bg-col-2 hover-box p-0">
            <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/07/2.png`} alt='about-img-in' className='about-img-in' width='100%' loading="lazy" />
              <div className="text-box">
                <h3 className="roboto-thin white">Selective</h3>
              </div>
              <div className="about-text-description">
                <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                </p>
              </div>
            </div>
            <div className="col bg-col-3 hover-box p-0">
            <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/07/3.png`} alt='about-img-in' className='about-img-in' width='100%' loading="lazy" />
              <div className="text-box">
                <h3 className="roboto-thin white">Authentic</h3>
              </div>
              <div className="about-text-description">
                <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                </p>
              </div>
            </div>
            <div className="col bg-col-4 hover-box p-0">
            <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/07/4-1.png`} alt='about-img-in' className='about-img-in' width='100%' loading="lazy" />
              <div className="text-box">
                <h3 className="roboto-thin white">Empowering</h3>
              </div>
              <div className="about-text-description">
                <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                </p>
              </div>
            </div>
            <div className="col bg-col-5 hover-box p-0">
            <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/07/5.png`} alt='about-img-in' className='about-img-in' width='100%' loading="lazy" />
              <div className="text-box">
                <h3 className="roboto-thin white">Giving Back</h3>
              </div>
              <div className="about-text-description">
                <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="integrity-mobile">
        <div className="container-fuild">
          <div className="row integrity-row-mobile">
            <div className="col-md-12 inter-mobile-col-1">
              <div className="inter-mobile-text">
                 <h3 className="roboto-thin white">Integrity</h3>
                 <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                 </p>
              </div>
            </div>

            <div className="col-md-12 inter-mobile-col-2">
                <div className="inter-mobile-text">
                <h3 className="roboto-thin white">Integrity</h3>
                 <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                 </p>
                </div>

            </div>

            <div className="col-md-12 inter-mobile-col-3">
                <div className="inter-mobile-text">
                <h3 className="roboto-thin white">Integrity</h3>
                 <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                 </p>
                </div>

            </div>
            <div className="col-md-12 inter-mobile-col-4">
                <div className="inter-mobile-text">
                <h3 className="roboto-thin white">Integrity</h3>
                 <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                 </p>
                </div>

            </div>

            <div className="col-md-12 inter-mobile-col-5">
                <div className="inter-mobile-text">
                <h3 className="roboto-thin white">Integrity</h3>
                 <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                 </p>
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-faq">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="container-faq">
                <div className="item">
                  <div className="item-header">
                    <div className="title">
                      <h2 className="roboto-light white">What we do?</h2>
                    </div>
                  </div>
                  <div className="item-body" style={{background: "linear-gradient(178deg, rgba(0, 0, 0, 0) 0%, rgba(153, 70, 212, 1) 1426%) -3%"}}>
                    <div>
                      <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="item-header">
                    <div className="title">
                      <h2 className="roboto-light white">What we do?</h2>
                    </div>
                  </div>
                  <div className="item-body" style={{background: "linear-gradient(178deg, rgba(0, 0, 0, 0) 0%, rgba(153, 70, 212, 1) 1426%) -3%"}}>
                    <div>
                      <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="item-header">
                    <div className="title">
                      <h2 className="roboto-light white">What we do?</h2>
                    </div>
                  </div>
                  <div className="item-body" style={{background: "linear-gradient(178deg, rgba(0, 0, 0, 0) 0%, rgba(153, 70, 212, 1) 1426%) -3%"}}>
                    <div>
                      <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-mobile-faq">
        <div className="container-fuild">
          <div className="row moble-row-faq">
            <div className="col-md-12 mobile-col-faq-1">
              <div className="mobile-faq-text">
                  <h2 className="roboto-light white">What we do?</h2>
                  <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  </p>
              </div>
            </div>

            <div className="col-md-12 mobile-col-faq-2">
              <div className="mobile-faq-text">
                  <h2 className="roboto-light white">What we do?</h2>
                  <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  </p>
              </div>
            </div>

            <div className="col-md-12 mobile-col-faq-3">
              <div className="mobile-faq-text">
                  <h2 className="roboto-light white">What we do?</h2>
                  <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  </p>
              </div>
            </div>


          </div>
        </div>
      </div>

      <div className="zoom-out-section gap-row">
        <div className="container-fluid">
        <div className="row">
        <div className="scroll-section">
      <div className="row m-0">
        <div className="col-md-12 image-col p-0">

        <div className="row profile-row-about p-0 m-0">
      {profiles.map((profile) => (
        <div className="col-md-3 m-0 p-0" key={profile.id}>
          <div className="profile-dev">
            <img
              src={profile.image}
              alt={`Zoomable ${profile.name}`}
              className="zoom-image"
              loading="lazy"
            />
            <div className="overlay">
              <h3 className="text-center white roboto-regular">{profile.name}</h3>
              <h4 className="text-center white roboto-regular">{profile.position}</h4>
            </div>
          </div>
        </div>
      ))}
    </div>

        
        
        </div>
        <div className="col-md-12 text-col">
          <div className="zoom-text-box">
          <h1 className="white roboto-regular about-h1"><span className="roboto-black">Grow your career </span> at the<br></br> heart of change</h1>
          <p className="roboto-regular white b1"> It's your to shine. bring your ingenuity, curioslity and big ideas.</p>
          <button className='roboto-medium header-text-style white pushable'>
                       <span className="shadow"></span>
                       <span className="edge"></span>
                       <span className="front header-text-style">Join us</span>
                    </button>
          </div>
        </div>
      </div>
    </div>
        </div>
        </div>
      </div>

      <div className="zoom-out-mobile">
        <div className="container-fluid">
          <div className="row zoom-mobile-row">
            <div className="col-md-6" style={{ backgroundImage: `url('https://api.listudiosl.com/wp-content/uploads/2024/10/Card-1.jpg')`,backgroundSize: 'cover',height:'180px', backgroundPosition: 'center', width: '50%'}}>
               <div className="profile">
                <p className="b2 white text-center poppins-bold">Member Name</p>
                <p className="b2 white text-center roboto-regular">Position</p>
              
               </div>
            </div>

            <div className="col-md-6" style={{ backgroundImage: `url('https://api.listudiosl.com/wp-content/uploads/2024/10/Card-2.jpg')`,backgroundSize: 'cover',height:'180px', backgroundPosition: 'center',width: '50%'}}>
            <div className="profile">
                <p className="b2 white text-center poppins-bold">Member Name</p>
                <p className="b2 white text-center roboto-regular">Position</p>
                
               </div>
            </div>

            <div className="col-md-6" style={{ backgroundImage: `url('https://api.listudiosl.com/wp-content/uploads/2024/10/Card-3.jpg')`,backgroundSize: 'cover',height:'180px', backgroundPosition: 'center',width: '50%'}}>
            <div className="profile">
                <p className="b2 white text-center poppins-bold">Member Name</p>
                <p className="b2 white text-center roboto-regular">Position</p>
                
               </div>
            </div>

            <div className="col-md-6" style={{ backgroundImage: `url('https://api.listudiosl.com/wp-content/uploads/2024/10/Card-4.jpg')`,backgroundSize: 'cover',height:'180px', backgroundPosition: 'center',width: '50%'}}>
            <div className="profile">
                <p className="b2 white text-center poppins-bold">Member Name</p>
                <p className="b2 white text-center roboto-regular">Position</p>
                
               </div>
            </div>

            <div className="col-md-6" style={{ backgroundImage: `url('https://api.listudiosl.com/wp-content/uploads/2024/10/Card-5.jpg')`,backgroundSize: 'cover',height:'180px', backgroundPosition: 'center',width: '50%'}}>
            <div className="profile">
                <p className="b2 white text-center poppins-bold">Member Name</p>
                <p className="b2 white text-center roboto-regular">Position</p>
                
               </div>
            </div>

            <div className="col-md-6" style={{ backgroundImage: `url('https://api.listudiosl.com/wp-content/uploads/2024/10/Card-6.jpg')`,backgroundSize: 'cover',height:'180px', backgroundPosition: 'center',width: '50%'}}>
            <div className="profile">
                <p className="b2 white text-center poppins-bold">Member Name</p>
                <p className="b2 white text-center roboto-regular">Position</p>
                
               </div>
            </div>

            <div className="col-md-6" style={{ backgroundImage: `url('https://api.listudiosl.com/wp-content/uploads/2024/10/Card-7.jpg')`,backgroundSize: 'cover',height:'180px', backgroundPosition: 'center',width: '50%'}}>
            <div className="profile">
                <p className="b2 white text-center poppins-bold">Member Name</p>
                <p className="b2 white text-center roboto-regular">Position</p>
                
               </div>
            </div>

            <div className="col-md-6" style={{ backgroundImage: `url('https://api.listudiosl.com/wp-content/uploads/2024/10/Card-8.jpg')`,backgroundSize: 'cover',height:'180px', backgroundPosition: 'center',width: '50%'}}>
            <div className="profile">
                <p className="b2 white text-center poppins-bold">Member Name</p>
                <p className="b2 white text-center roboto-regular">Position</p>
                
               </div>
            </div>

          

           

            <div className="col-md-12 btn-profile">
            <div className="join-us-btn">
                     <button className='roboto-medium header-text-style white pushable'>
                       <span className="shadow"></span>
                       <span className="edge"></span>
                       <span className="front header-text-style">View more</span>
                    </button>
                     </div>
            </div>

          </div>
        </div>
      </div>

      <div className="Testimonil-page section-gap-new">
        
        <div className="container-fluid">
          <div className="row gap-row">
            <div className="col-md-12">
              <h2 className="roboto-light Dark-Blue">Hear from our <span className=" Dark-Blue roboto-black">clients</span></h2>
            </div>
            <div className="col-md-12 p-0">
             <div className="testimonial-slider">
             <Swiper
        slidesPerView={'auto'}
        navigation={true}
        spaceBetween={30}
        pagination={{
          type: 'fraction',
        }}
        
        modules={[Pagination,Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>

          <div className="container-fuild">
            <div className="row">
          
              <div className="col-md-7">
                  <div className="testi-video">
                    <h3 className="roboto-light Gray text-left">“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.”</h3>
                    <div className="name-text-box">
                      <p className="roboto-medium b1 Dark-Blue p-0 m-0">James Freeman</p>
                      <p className="b2 roboto-light Gray m-0 p-0">CEO, Company Name</p>
                    </div>
                  </div>
              </div>

              <div className="col-md-5">
                 <div className="texti-box">
                      <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Group-10526.png`} alt="" width={983} height={440} loading="lazy" />
                 </div>
              </div>
            </div>
          </div>

        </SwiperSlide>
        <SwiperSlide>
        <div className="container-fuild">
            <div className="row">
          
            <div className="col-md-7">
                  <div className="testi-video">
                    <h3 className="roboto-light Gray text-left">“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.”</h3>
                    <div className="name-text-box">
                      <p className="roboto-medium b1 Dark-Blue p-0 m-0">James Freeman</p>
                      <p className="b2 roboto-light Gray m-0 p-0">CEO, Company Name</p>
                    </div>
                  </div>
              </div>

              <div className="col-md-5">
                 <div className="texti-box">
                 <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Group-10526.png`} alt="" width={983} height={440} loading="lazy" />
                 </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        
        
      </Swiper>
            
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    </HelmetProvider>
  );
};

export default AboutScreen;
