import { useLayoutEffect, useRef } from "react";

import ReactPlayer from 'react-player';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import { Link } from 'react-router-dom';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);


const LifeLIScreen = () => {

   const component = useRef();
   const slider = useRef();
   const panels = useRef([]);
   const careersSection = useRef();
   
   useLayoutEffect(() => {
     let ctx = gsap.context(() => {
       // Match media for responsive behavior
       ScrollTrigger.matchMedia({
         // Desktop and larger screens
         "(min-width: 768px)": () => {
           panels.current = gsap.utils.toArray(".panel"); // Store panels in a ref
           let totalPanels = panels.current.length;
   
           // Horizontal scroll animation for panels
           gsap.to(panels.current, {
             xPercent: -100 * (totalPanels - 1),
             ease: "none",
             scrollTrigger: {
               trigger: slider.current,
               pin: true,
               scrub: 1,
               snap: 1 / (panels.current.length - 1),
               end: () => "+=" + slider.current.offsetWidth,
               onUpdate: (self) => {
                 // Calculate the active panel based on scroll progress
                 let panelIndex = Math.round(self.progress * (totalPanels - 1));
   
                 // Remove 'active' class from all panels
                 panels.current.forEach(panel => panel.classList.remove('active'));
   
                 // Add 'active' class to the current panel
                 if (panels.current[panelIndex]) {
                   panels.current[panelIndex].classList.add('active');
                 }
               },
               onComplete: () => {
                 // Once the scroll reaches the end, smoothly scroll to "Our Careers" section
                 gsap.to(window, { duration: 1, scrollTo: careersSection.current, ease: "power2.inOut" });
               },
             }
           });
         },
   
         // Mobile and smaller screens
         "(max-width: 767px)": () => {
           // Disable the animation for mobile devices
           ScrollTrigger.getAll().forEach(trigger => trigger.kill());
           // Reset any transforms applied by the animation
           gsap.set(panels.current, { clearProps: "all" });
         }
       });
     }, component);
   
     return () => ctx.revert();
   }, []);
   
   

   


return (
<div className="LifeLIScreen">
   <div className="our-culture">
      <div className="container-fuild">
         <div className="row cultrue-row">
            <div className="col-md-12">
               <div className="cultrue-box">
                  <h2 className='roboto-light white'>Our <span className='roboto-black white '>Culture</span></h2>
                  <p className='b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor sit amet, </p>
               </div>
            </div>
         </div>

         <div className="row row-mesonory" id="mesonory-desktop">
            <div className="col-md-12">
               <div className="mesonory-grid">
                  <ResponsiveMasonry columnsCountBreakPoints={{ 750: 1, 250: 2, 900: 3}}>
                  <Masonry  gutter="16px" >
                     {/* First Column: Text Box */}
                     <div className='mesonory-text-grid-box-1'>
                        <h3 className='roboto-light white'>Our creativity allows us to imagine new possibilities</h3>
                     </div>
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17243.png`} loading="lazy" alt="" width="100%" height="auto" />
                     {/* Third Column: Text Box */}
                     <div className='mesonory-text-grid-box-2'>
                        <h3 className='roboto-light white'>We owe it to all of our clients.</h3>
                     </div>
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17245.png`} loading="lazy" alt="" width="100%" height="auto"/>
                     {/* Third Column: Text Box */}
                     <div className='mesonory-text-grid-box-3'>
                        <h3 className='roboto-light white'>Our creativity allows us to imagine new possibilities</h3>
                     </div>
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17246.png`} loading="lazy" alt="" width="100%" height="auto" />
                  </Masonry>
                  </ResponsiveMasonry>
               </div>
            </div>
         </div>
           
         <div className="row row-mesonory" id="mesonory-mobile">
            <div className="col-md-12">
               <div className="mesonory-grid" gutter="16px">
              
                     {/* First Column: Text Box */}
                     <div className='mesonory-text-grid-box-1'>
                        <h3 className='roboto-light white'>Our creativity allows us to imagine new possibilities</h3>
                     </div>
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17243.png`} loading="lazy" alt="" width="100%" height="auto" />
                     {/* Third Column: Text Box */}
                     <div className='mesonory-text-grid-box-2'>
                        <h3 className='roboto-light white'>We owe it to all of our clients.</h3>
                     </div>
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17245.png`} loading="lazy" alt="" width="100%" height="auto"/>
                     {/* Third Column: Text Box */}
                     <div className='mesonory-text-grid-box-3'>
                        <h3 className='roboto-light white'>Our creativity allows us to imagine new possibilities</h3>
                     </div>
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17246.png`} loading="lazy" alt="" width="100%" height="auto" />
               
                 
               </div>
            </div>
         </div>  
         

      </div>
   </div>
   
   <div className="App scroll-li-section" ref={component}>
      <div ref={slider} className="containerr">
         <div className="panel zero-sect">
            <div className="logo-text-li">
               <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/09/1-5-1.png`} alt="logo-li" width="100%" height="auto" loading="lazy" />
               <div className="logo-body">
                  <h1 className='logo-body-h1 text-center poppins-bold white'>Sixteen Years <br></br><span className='logo-body-span text-center poppins-light white'>In Business</span></h1>
               </div>
            </div>
         </div>
         <div className="panel first-sect">
            <div className="information-section">
               <h2 className='roboto-black white'>2008</h2>
               <h3 className='roboto-medium white'>First official outing with the team</h3>
               <p className='b1 Tone-Down'>Last weekend we decided to head down south. Here are some highlights from the trip. Stay tuned for more LI weekend excursions.</p>
            </div>
            <div className="row panell-gallery-row">
               <div className="col-md-6">
                  <ResponsiveMasonry columnsCountBreakPoints={{ 750: 1, 250: 2, 900: 3}}>
                  <Masonry  gutter="20px" >
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17243.png`} loading="lazy" alt="" width="100%" height="auto" />
                     {/* Third Column: Text Box */}
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17245.png`} loading="lazy" alt="" width="100%" height="auto"/>
                     {/* Third Column: Text Box */}
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17246.png`} loading="lazy" alt="" width="100%" height="auto" />
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17246.png`} loading="lazy" alt="" width="100%" height="auto" />
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17245.png`} loading="lazy" alt="" width="100%" height="auto"/>
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17243.png`} loading="lazy" alt="" width="100%" height="auto" />
                  </Masonry>
                  </ResponsiveMasonry>
               </div>
               <div className="col-md-6 slider-responsive-image">
                  <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17245.png`} loading="lazy" alt="" width="100%" height="auto"/>
               </div>
            </div>
         </div>
         <div className="panel secound-sect">
            <div className="information-section">
               <h2 className='roboto-black white'>2014</h2>
               <h3 className='roboto-medium white'>Simply the Best</h3>
               <p className='b1 Tone-Down'>2014 Lion National Sales Conference Lion Breweries awarded us the greatest event of the year. National Sales Conference 2014. Our ambition was to create a store that would reflect the simplicity.</p>
            </div>
            <div className="row panell-gallery-row">
               <div className="col-md-6">
                  <ResponsiveMasonry columnsCountBreakPoints={{ 750: 1, 250: 2, 900: 3}}>
                  <Masonry  gutter="16px" >
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17243.png`} loading="lazy" alt="" width="100%" height="auto" />
                     {/* Third Column: Text Box */}
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17245.png`} loading="lazy" alt="" width="100%" height="auto"/>
                     {/* Third Column: Text Box */}
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17246.png`} loading="lazy" alt="" width="100%" height="auto" />
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17246.png`} loading="lazy" alt="" width="100%" height="auto" />
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17245.png`} loading="lazy" alt="" width="100%" height="auto"/>
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17243.png`} loading="lazy" alt="" width="100%" height="auto" />
                  </Masonry>
                  </ResponsiveMasonry>
               </div>
               <div className="col-md-6 slider-responsive-image">
                  <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17245.png`} loading="lazy" alt="" width="100%" height="auto"/>
               </div>
            </div>
         </div>
         <div className="panel third-sect">
            <div className="information-section">
               <h2 className='roboto-black white'>2015</h2>
               <h3 className='roboto-medium white'>Pastry shop photography</h3>
               <p className='b1 Tone-Down'>Art directing a food photo shoot is all about balance. You need to find a way to be absurdly diligent about the details while also keeping an unwavering eye on the big picture.</p>
            </div>
            <div className="row panell-gallery-row">
               <div className="col-md-6">
                  <ResponsiveMasonry columnsCountBreakPoints={{ 750: 1, 250: 2, 900: 3}}>
                  <Masonry  gutter="16px" >
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17243.png`} loading="lazy" alt="" width="100%" height="auto" className="zoom"/>
                     {/* Third Column: Text Box */}
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17245.png`} loading="lazy" alt="" width="100%" height="auto" className="zoom"/>
                     {/* Third Column: Text Box */}
                     {/* Second Column: Image */}
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17246.png`} loading="lazy" alt="" width="100%" height="auto" className="zoom" />
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17246.png`} loading="lazy" alt="" width="100%" height="auto" className="zoom" />
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17245.png`} loading="lazy" alt="" width="100%" height="auto" className="zoom"/>
                     <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17243.png`} loading="lazy" alt="" width="100%" height="auto" className="zoom" />
                  </Masonry>
                  </ResponsiveMasonry>
               </div>
               <div className="col-md-6 slider-responsive-image">
                  <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17245.png`} loading="lazy" alt="" width="100%" height="auto"/>
               </div>
            </div>
         </div>
      </div>
   </div>


   <div className="our-careers" ref={careersSection}>
      <div className="container-fuild">
         <div className="row our-careers-row">
            <div className="col-md-12 our-careers-col">
               <div className="our-carees-text-box">
                  <h2 className='roboto-light white'>Careers at <span className='roboto-black white '>LI</span></h2>
                  <p className='b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
               </div>
            </div>
         </div>
         <div className="row" id="our-careers">
            <div className="col-md-12 p-0">
               <div className="container-faq">
                  <div className="item">
                     <div className="item-header">
                        <div className="title">
                           <h2 className="roboto-light white">UI/UX Designer</h2>
                        </div>
                     </div>
                     <div className="item-body" style={{background: "linear-gradient(178deg, rgba(0, 0, 0, 0) 0%, rgba(153, 70, 212, 1) 1426%) -3%"}}>
                     <div className='faq-box-btn-text'>
                        <p className="roboto-regular Tone-Down b1">
                           Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        </p>
                        <Link reloadDocument to= "/careers">
                        <button className="roboto-medium header-text-style white pushable"><span className="shadow"></span><span className="edge"></span><span className="front header-text-style">Apply Now</span></button></Link> 
                     </div>
                  </div>
               </div>
               <div className="item">
                  <div className="item-header">
                     <div className="title">
                        <h2 className="roboto-light white">Graphic Designer</h2>
                     </div>
                  </div>
                  <div className="item-body" style={{background: "linear-gradient(178deg, rgba(0, 0, 0, 0) 0%, rgba(24, 207, 174, 1) 1426%) -3%"}}>
                  <div className='faq-box-btn-text'>
                     <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                     </p>
                     <button className="roboto-medium header-text-style white pushable"><span className="shadow"></span><span className="edge"></span><span className="front header-text-style">Apply Now</span></button>
                  </div>
               </div>
            </div>
            <div className="item">
               <div className="item-header">
                  <div className="title">
                     <h2 className="roboto-light white">Software Engineer</h2>
                  </div>
               </div>
               <div className="item-body" style={{background: "linear-gradient(178deg, rgba(0, 0, 0, 0) 0%, rgba(26, 140, 244, 1) 1426%) -3%"}}>
               <div className='faq-box-btn-text'>
                  <p className="roboto-regular Tone-Down b1">
                     Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  </p>
                  <button className="roboto-medium header-text-style white pushable"><span className="shadow"></span><span className="edge"></span><span className="front header-text-style">Apply Now</span></button>
               </div>
            </div>
         </div>
         <div className="item">
            <div className="item-header">
               <div className="title">
                  <h2 className="roboto-light white">Project Manager</h2>
               </div>
            </div>
            <div className="item-body" style={{background: "linear-gradient(178deg, rgba(0, 0, 0, 0) 0%, rgba(153, 70, 212, 1) 1426%) -3%"}}>
            <div className='faq-box-btn-text'>
               <p className="roboto-regular Tone-Down b1">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
               </p>
               <button className="roboto-medium header-text-style white pushable"><span className="shadow"></span><span className="edge"></span><span className="front header-text-style">Apply Now</span></button>
            </div>
         </div>
      </div>
   </div>
</div>
</div>
</div>
    </div>

    <div className="about-mobile-faq" id="about-mobile-faq">
        <div className="container-fuild">
          <div className="row moble-row-faq">
            <div className="col-md-12 mobile-col-faq-1">
              <div className="mobile-faq-text">
                  <h2 className="roboto-light white">UI/UX Designer</h2>
                  <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  </p>
              </div>
            </div>

            <div className="col-md-12 mobile-col-faq-2">
              <div className="mobile-faq-text">
                  <h2 className="roboto-light white">Graphic Designer</h2>
                  <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  </p>
              </div>
            </div>

            <div className="col-md-12 mobile-col-faq-3">
              <div className="mobile-faq-text">
                  <h2 className="roboto-light white">Software Engineer</h2>
                  <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                  </p>
              </div>
            </div>


          </div>
        </div>
    </div>


<div className="working">
   <div className="container-fuild">
      <div className="row working-row">
         <div className="col-md-5 working-box-col">
            <div className="working-box">
               <h2 className='roboto-light white'>Our <span className='roboto-black white'>Working Environment</span></h2>
               <p className='roboto-regular Tone-Down b1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
            </div>
         </div>
         <div className="col-md-2 working-img-1">
            <div className="working-img">
               <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17258.png`} loading="lazy" alt="" width="100%" height="450px" />
            </div>
         </div>
         <div className="col-md-3 working-img-2">
            <div className="working-img">
               <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17259.png`} loading="lazy" alt=""  width="100%" height="450px"/>
            </div>
         </div>
         <div className="col-md-2 working-img-3">
            <div className="working-img">
               <ReactPlayer
                  url='https://api.listudiosl.com/wp-content/uploads/2024/08/7377732363599564033.mp4'
                  loop
                  controls={false}
                  playing={true}
                  muted={true}
                  width='100%'
                  height='450px'
                  loading="lazy"
                  />
            </div>
         </div>
      </div>
      <div className="row working-row-next">
         <div className="col-md-2 working-img">
            <div className="working-img">
               <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17291.png`} alt="" width="100%" height="450px" />
            </div>
         </div>
         <div className="col-md-2 working-img">
            <div className="working-img">
               <ReactPlayer
                  url='https://api.listudiosl.com/wp-content/uploads/2024/08/7369943382786870529.mp4'
                  loop
                  controls={false}
                  playing={true}
                  muted={true}
                  width='100%'
                  height='450px'
                  loading="lazy"
                  />
            </div>
         </div>
         <div className="col-md-3 working-img">
            <div className="working-img">
               <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17247.png`} alt="" width="100%" height="450px" />
            </div>
         </div>
         <div className="col-md-5 working-img">
            <div className="working-img">
               <img src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/08/Rectangle-17264.png`} alt="" width="100%" height="450px" />
            </div>
         </div>
      </div>
   </div>
</div>


<div className="benifits">
   <div className="container-fuild">
      <div className="row benifts-row">
         <div className="col-md-12 col-text-benifts">
            <div className="benifts-text-box">
               <h2 className='roboto-light Dark-Blue'>Unlock Exclusive <span className='roboto-black Dark-Blue'>Benefits</span></h2>
            </div>
         </div>
      </div>
      <div className="row benifts-faq-row">
         <div className="col-md-7 benifts-col-faq-text">
            <div className="benifts-faq-text">
               <h3 className='roboto-light Dark-Blue'>Your Benefits at LI</h3>
               <p className='roboto-light Dark-Blue b1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
               <p className='roboto-light Dark-Blue b1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
            </div>
         </div>
         <div className="col-md-5 benifts-col-faq">
            <div className="benifts-faq">
               <details open>
                  <summary className='faq-header'>
                     <h3 className='roboto-light Dark-Blue'>How do I create accordion?</h3>
                  </summary>
                  <div className='faq-body'>
                     <ul className='faq-ul'>
                        <li className='faq-li roboto-light Dark-Blue b1'>Subsidized healthcare for you and your family</li>
                        <li className='faq-li roboto-light Dark-Blue b1'>A safe, ethical, secure, and healthy workplace</li>
                        <li className='faq-li roboto-light Dark-Blue b1'>Mental health and wellbeing support</li>
                     </ul>
                  </div>
               </details>
               <details>
                  <summary className='faq-header'>
                     <h3 className='roboto-light Dark-Blue'>Financial Well-Being</h3>
                  </summary>
                  <div className='faq-body'>
                     <ul className='faq-ul'>
                        <li className='faq-li roboto-light Dark-Blue b1'>Subsidized healthcare for you and your family</li>
                        <li className='faq-li roboto-light Dark-Blue b1'>A safe, ethical, secure, and healthy workplace</li>
                        <li className='faq-li roboto-light Dark-Blue b1'>Mental health and wellbeing support</li>
                     </ul>
                  </div>
               </details>
               <details>
                  <summary className='faq-header'>
                     <h3 className='roboto-light Dark-Blue'>Personal Development</h3>
                  </summary>
                  <div className='faq-body'>
                     <ul className='faq-ul'>
                        <li className='faq-li roboto-light Dark-Blue b1'>Subsidized healthcare for you and your family</li>
                        <li className='faq-li roboto-light Dark-Blue b1'>A safe, ethical, secure, and healthy workplace</li>
                        <li className='faq-li roboto-light Dark-Blue b1'>Mental health and wellbeing support</li>
                     </ul>
                  </div>
               </details>
            </div>
         </div>
      </div>
   </div>
</div>
</div>
)
}
export default LifeLIScreen