import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";



import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import HomeScreen from './Screens/HomeScreen.jsx';
import AboutScreen from './Screens/AboutScreen.jsx';
import PortfolioScreen from './Screens/PortfolioScreen.jsx';
import SurfBlissScreen from './Screens/CaseStudy/SurfBlissScreen.jsx';
import ClientStoriesScreen from './Screens/ClientStoriesScreen.jsx';
import LifeLIScreen from './Screens/LifeLIScreen.jsx';
import CareersScreen from './Screens/CareersScreen.jsx';
import ContactScreen from './Screens/ContactScreen.jsx';



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route index={true} path='/' element={<HomeScreen />} />
      <Route  path='/about' element={<AboutScreen />} />
      <Route  path='/portfolio' element={<PortfolioScreen />} />
      <Route  path='/clientstories' element={<ClientStoriesScreen />} />
      <Route  path='/lifeli' element={<LifeLIScreen />} />
      <Route  path='/careers' element={<CareersScreen />} />
      <Route  path='/contact' element={<ContactScreen />} />

      <Route  path='/surfbliss' element={<SurfBlissScreen />} />
    </Route>
  )
);




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
