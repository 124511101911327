import React from 'react';


const SurfBlissScreen = () => {

  return (
   
    <div className="SurfBlissScreen">

        <div className="SurfBlissScreen-banner">
           <div className="container-fuild">
                <div className="row SurfBlissScreen-banner-row">
                   <div className="col-md-12 text-col-casestudy-innner">
                      <div className="video-player">
                     

                       <video
                         src="https://api.listudiosl.com/wp-content/uploads/2025/01/beach-anim-1.mp4"
                          autoPlay
                          loading="lazy" 
                          loop
                           muted
                          controls={false}
                            playsInline
                           style={{ width: '100%',height: 'auto', objectFit: 'cover' }}
                      />
                  </div>
                   </div>
                </div>
            </div>
        </div>

        <div className="SufLogo">
          <div className="container-fuild">
            <div className="row SufLogo-row">
              <div className="col-md-12 SufLogo-col">
                <div className="SufLogo">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2025/01/Sun-Bliss-logo.png"   loading="lazy"  width="100%" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="challenge-page">
         <div className="container">
          <div className="row challege-row p-0 m-0">
            <div className="col-md-6 challenge-text-col">
                  <div className="challenge-text-box">
                         <h3 className='roboto-light Gray-1'>The Challenge</h3>
                         <p className='Gray-1 b1 roboto-light'>The primary challenge was to create a brand name that was  iconic, simple, and memorable, while also developing a  distinctive and meaningful brand identity. The logo needed to  be symbolic and instantly recognizable, encapsulating the  spirit of the brand.</p>
                  </div>

                  <div className="challenge-text-box-two">
                         <h3 className='roboto-light Gray-1'>Solution</h3>
                         <p className='Gray-1 b1 roboto-light'>The name "Bliss" was chosen for its universal appeal and  extended to sub-brands like Surf Bliss and Yoga Bliss to  represent different branches. The Surf Bliss logo features a fire  torch within the letter "i", symbolizing warmth, guidance, and the  rejuvenation it offers travelers. This cohesive identity captures  the essence of comfort and adventure, making Surf Bliss a  beacon for seaside relaxation.</p>
                  </div>
            </div>
            <div className="col-md-6 challenge-gif-col">
              <div className="challenge-gif">
                     <img src="https://api.listudiosl.com/wp-content/uploads/2025/01/bliss-anim.gif"   loading="lazy"  height="auto" width="100%" alt="" />
              </div>

            </div>
          </div>
         </div>
        </div>

        <div className="SufMesonorygrid">
          <div className="container">
            <div className="row surMesonorygrid-row">
              <div className="col-md-12 surmesaonory-grid-col">
                <div className="Mesonorygrid-sur">
                <div class="masonry-ls">
        <div class="item item1"></div>
        <div class="item item2"></div>
        <div class="item item3"></div>
        <div class="item item4"></div>
        <div class="item item5"></div>
        <div class="item item6"></div>
        <div class="item item7"></div>
        <div class="item item8"></div>
        <div class="item item9"></div>
        <div class="item item10"></div>
        <div class="item item11"></div>
       
    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

       

        <div className="SufMesonoryBort">
          <div className="container-fuild">
            <div className="row surMesonoryBort-row">
              <div className="col-md-12 surmesaonory-bort-col">
                <div className="bort-sur">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2025/01/LI-Bliss-Sea-Beach-Image-1-1.jpg"   loading="lazy"  width="100%" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="SufMesonoryHouse">
          <div className="container-fuild">
            <div className="row surMesonoryHouse-row">
              <div className="col-md-12 surmesaonory-House-col">
                <div className="House-sur">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2025/01/LI-Bliss-Image.jpg"   loading="lazy"  width="100%" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Collaterals">
          <div className="container">
              <div className="row Collaterals-row">
                <div className="col-md-6 Collaterals-col-text">
                  <div className="Collaterals-text">
                        <h3 className='roboto-light Gray-1'>Collaterals</h3>
                         <p className='Gray-1 b1 roboto-light'>Experience the essence of adventure  and tranquility with Surf Bliss. From  stunning designs to memorable stays,  our branding reflects the perfect balance  of comfort and seaside inspiration</p>
                  </div>
                </div>
                <div className="col-md-6 Collaterals-image">
                  <div className="Collaterals-image">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2025/01/LI-Bliss-Collaterals.png"   loading="lazy"  height="auto" width="100%" alt="" />
                  </div>
                </div>
              </div>
          </div>
        </div>

        {/* <div className="SufMesonoryNess">
          <div className="container-fuild">
            <div className="row surMesonoryNess-row">
              <div className="col-md-12 surmesaonory-Ness-col">
                <div className="Ness-sur">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2025/01/Bliss-Image-1-1.jpg"   loading="lazy"  width="100%" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="SufMesonoryNext">
          <div className="container-fuild">
            <div className="row surMesonoryNext-row">
              <div className="col-md-12 surmesaonory-Next-col">
                <div className="Next-sur">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2025/01/Bliss-Image-2-1.jpg"   loading="lazy"  width="100%" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="SufMesonoryMap">
          <div className="container-fuild">
            <div className="row surMesonoryMap-row">
              <div className="col-md-12 surmesaonory-Map-col">
                <div className="Map-sur">
                <video
                         src="https://api.listudiosl.com/wp-content/uploads/2025/01/Map-animation.mp4"
                          autoPlay
                          loading="lazy" 
                          loop
                           muted
                          controls={false}
                            playsInline
                           style={{ width: '100%', objectFit: 'cover' }}
                      />
                 
                </div>
              </div>
            </div>
          </div>
        </div>



    </div>

  )
}

export default SurfBlissScreen