import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Lottie from 'react-lottie';
import * as animationData from '../../src/json/LI_home_up-line.json';

import * as LogoData from '../../src/json/logo.json';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef(null);

  const openNav = () => setIsOpen(true);
  const closeNav = () => setIsOpen(false);

  const [windowDimension, setWindowDimension] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowDimension(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = windowDimension <= 640;

  const Headeranimation = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const Headerlogo = {
    loop: true,
    autoplay: true, 
    animationData: LogoData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };



  // Handle scroll event
  const handleScroll = () => {
    if (headerRef.current) {
      // Check if the pageYOffset is greater than the top offset of the header
      const headerTop = headerRef.current.offsetTop;
      if (window.pageYOffset > headerTop) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
  };

  // Add/remove scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    {!isMobile ? (
    <div className={`Header${isSticky ? ' sticky' : ''}`} ref={headerRef}>
      <div className="container-fluid section-gap">
        <div className="row">
          <div className="col-md-3 header-row-one">

          <div className={`header-log-col ${isSticky ? 'sticky-logo' : 'non-sticky-logo'}`}>
          <Link reloadDocument  to= "/">
            {isSticky ? (
              <Lottie options={Headerlogo} height='43px' width='43px'/>
            ) : (
              <img
                src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/05/LI-Logo-White.png`}
                alt="Li-logo"
                className="li-logo"
                width="43"
                height="43"
              />
            )}
          </Link>
        </div>

         
          </div>
          <div className="col-md-6 header-row-two">
            <div className="header-nav-list">
              <ul className="header-col-ul">
                <li className="roboto-medium header-text-style">
                  <Link reloadDocument  to= "/portfolio"><span>Portfolio</span></Link>
                </li>
                <li className="roboto-medium header-text-style">
                  <Link reloadDocument  to= "/about"><span>About us</span></Link>
                </li>
                <li className="roboto-medium header-text-style">
                  <Link reloadDocument to= "/lifeli"><span>Life at LI</span></Link>
                </li>
                <li className="roboto-medium header-text-style">
                  <Link reloadDocument to = "/clientstories"><span>Client stories</span></Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 header-row-three">
            <a href="/contact">
            <div className="header-button-left">
              <button className="roboto-medium header-text-style white pushable">
                <span className="shadow"></span>
                <span className="edge"></span>
                <span className="front header-text-style">Talk to us</span>
              </button>
            </div>
            </a>
          </div>
        </div>
        
      </div>
      <div  className={`header-line-animation${isSticky ? ' sticky-animation' : ''}`} ref={headerRef}>
             <Lottie options={Headeranimation} height='auto' width='100%'/>
            </div>
    </div>
    ):(

    <>
      <div
         className={`sidepanel${isOpen ? ' open' : ''}`}
        style={{
          width: isOpen ? '100%' : '0',
          transition: '0.5s',
        }}
      >
        <div className="slidepanel-body">
          <div className="container-fuild">
            <div className="row sidepanel-body-row p-0 m-0">
              <div className="col-md-6 sliderpanel-logo-col">

              <div className="log-mobile-box-panel">
                <a href="/">
               <img
                src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/07/LI-Logo-Full-Mobile.png`}
                alt="Li-logo"
                className="li-logo"
                width="138"
                height="39"
              />
              </a>
               </div>

              </div>
              <div className="col-md-3 slidepanle-col-btn">

              <a href="/contact">
            <div className="header-button-left">
              <button className="roboto-medium header-text-style white pushable">
                <span className="shadow"></span>
                <span className="edge"></span>
                <span className="front header-text-style">Talk to us</span>
              </button>
            </div>
            </a>

              </div>
              <div className="col-md-3 slidepanel-col-close">
              <a className="closebtn" onClick={closeNav}>×</a>
              </div>
            </div>
          </div>
        </div>
       
      </div>


      <div className={`mobile-nav${isSticky ? ' sticky' : ''}`} ref={headerRef}>
       <div className="container-fluid mobile-co">
          <div className="row mobile-row">
            <div className="col-md-12 mobile-logo-col">
               <div className="log-mobile-box">
                <a href="/">
               <img
                src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/07/LI-Logo-Full-Mobile.png`}
                alt="Li-logo"
                className="li-logo"
                width="138"
                height="39"
              />
              </a>
               </div>
               <div className="menu-logo" onClick={openNav}>
               <img
                src={`${process.env.REACT_APP_API_URL}/wp-content/uploads/2024/07/Mob-Menu.png`}
                alt="Menu-Icon"
                className="Menu-Icon"
                width="34"
                height="20"
              />
               </div>
            </div>
            
          </div>
       </div>
      </div>

      </>
    )}

    </>
  );
};

export default Header;
