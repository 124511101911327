import React,{useEffect,useState} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import './App.css';

import Header from './Includes/Header.jsx';
import Footer from './Includes/Footer.jsx';
import SkyBackground from './Components/skyBacgroud.jsx';
import AnimationsSky from './Components/animationsky.jsx';


import {Rings } from 'react-loader-spinner';

import SmoothScroll from './Components/SmoothScroll.js';

const App = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true); // State to track loader visibility


    // Define routes where SmoothScroll should not be used
    const excludeSmoothScroll = ['/lifeli'];
    const useSmoothScroll = !excludeSmoothScroll.includes(location.pathname);
  
  const showSkyComponents = !['/about', '/casestudyinner','/surfbliss'].includes(location.pathname);


  const pageId = location.pathname === '/' ? 'home' : location.pathname.slice(1);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.className = pageId; 
      rootElement.setAttribute('data-page-id', pageId); 
    }

     // Show loader when the route changes
     setLoading(true);
     const timer = setTimeout(() => setLoading(false), 4000); // Simulate loading delay
 
     return () => clearTimeout(timer); // Cleanup timeout on unmount


  }, [pageId]); 

  return (
    <>
      {useSmoothScroll ? (
        <>
          {loading ? (
            <div className="loader-container">
              <Rings
                visible={true}
                height="80"
                width="80"
                color="#9946D4"
                ariaLabel="oval-loading"
              />
            </div>
          ) : (
            <>
              <Header />
              <SmoothScroll>
                <div className="Li-web-pages">
                  <div className="div-sky-bg">
                    {showSkyComponents && <AnimationsSky />}
                    {showSkyComponents && <SkyBackground />}
                  </div>
                  <Outlet />
                </div>
             
              <Footer />
              </SmoothScroll>
            </>
          )}
        </>
      ) : (
        <>
          {loading ? (
            <div className="loader-container">
              <Rings
                visible={true}
                height="80"
                width="80"
                color="#9946D4"
                ariaLabel="oval-loading"
              />
            </div>
          ) : (
            <>
              <Header />
              <div className="Li-web-pages">
                <div className="div-sky-bg">
                  {showSkyComponents && <AnimationsSky />}
                  {showSkyComponents && <SkyBackground />}
                </div>
                <Outlet />
              </div>
              <Footer />
            </>
          )}
        </>
      )}
    </>
  );  
};

export default App;
